import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const VisuallyHidden = ({ children, ...props }) => {
  return <Styled.VisuallyHidden {...props}>{children}</Styled.VisuallyHidden>;
};

VisuallyHidden.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VisuallyHidden;

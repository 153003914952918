import React from 'react';
import get from 'just-safe-get';
import {
  Box,
  Flex,
  rem,
  Image,
  Grid,
  Container,
  Heading,
} from '@feast-it/pesto';
import { useInView } from 'react-intersection-observer';

import VisuallyHidden from '../../components/VisuallyHidden';

const RecommendationsPress = rawData => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const data = {
    heading: rawData?.primary?.heading,
    items: rawData?.items || [],
  };

  return (
    <Box
      as="section"
      py={8}
      px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
      ref={ref}
    >
      <Container>
        {data.heading ? (
          <Heading
            as="h2"
            variant="headingSmall"
            color="greyDark"
            textAlign="center"
            mb={4}
          >
            {data.heading}
          </Heading>
        ) : (
          <VisuallyHidden>
            <h2>Recommendations</h2>
          </VisuallyHidden>
        )}

        <Grid
          gridTemplateColumns={{
            _: `repeat(3, 1fr)`,
            l: `repeat(6, 1fr)`,
          }}
          gridColumnGap={{ _: 'gutter.s', m: 'gutter.m' }}
          gridRowGap={8}
        >
          {data.items.map((item, i) => {
            const logo = get(item, 'press_logo.url');
            const alt = get(item, 'press_logo.alt');

            return (
              <Flex
                key={`recommended-vendor-${i}`}
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Box maxWidth={{ _: rem(100), s: rem(140) }} width="100%">
                  <Image
                    src={logo}
                    alt={alt}
                    width={140}
                    inView={inView}
                    imgix
                  />
                </Box>
              </Flex>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default RecommendationsPress;
